<script>
/**
 * Plugin Service Settings uses the Design Settings because of json based.
 * There is no need to new component to develop new setting input etc.
 */
import DesignSettings from "./DesignSettings";
import plugins from "@/types/design-elements/plugins/index.ts";
import services from "@/types/design-elements/services/index.ts";
import { mapMutations } from "vuex";
export default {
  name: "PluginServiceSettings",
  extends: DesignSettings,
  data() {
    return {
      renderOptions: {
        move: false,
      },
    };
  },
  computed: {
    info() {
      return this.selectedObject?.info ?? [];
    },
    settings() {
      if (this.selectedObject?.structure?.key) {
        return {
          ...plugins,
          ...services,
        }[this.selectedObject?.structure.key].settings;
      }

      return [];
    },
    objectId() {
      return this.selectedObject?.structure?.key;
    },
  },
  watch: {
    // @override
    targetId: () => 0,
  },
  methods: {
    ...mapMutations("workspace/session", ["deleteService", "deletePlugin"]),
    deleteDesignElementThenResetTargetId() {
      // WARNING: args is a problem because of extend. It gets last targetId of design element.
      const key = this.selectedObject?.structure.key;
      if (!key) return;

      const isPlugin = !!plugins[key];
      if (isPlugin) {
        this.deletePlugin(key);
      } else {
        const isService = !!services[key];
        if (isService) {
          this.deleteService(key);
        }
      }

      this.$root.$emit("workspace/settings::targetId", "page1");

      return;
    },
  },
};
</script>
