export default {
  info: {
    disabled: false,
    title: "Face Detection",
    img: require(`../../../assets/img/icons/face-detec-1.png`),
  },
  structure: {
    key: "FaceDetection",
  },
};
