<script>
import { mapState, mapActions, mapMutations } from "vuex";
import _ from "lodash";

const createComponent = (node, h, self) => {
  // Handle empty elements and return empty array in case the node passed in is empty
  if (_.isEmpty(node)) {
    return [];
  }

  // if the el is array call createComponent for all elements
  if (_.isArray(node)) {
    return node.map((child) => createComponent(child, h, self));
  }

  const children = [];
  if (node.children && node.children.length > 0) {
    node.children.forEach((c) => {
      if (_.isString(c)) {
        children.push(c);
      } else {
        children.push(createComponent(c, h, self));
      }
    });
  }

  // Need to clone
  let properties = _.cloneDeep(node.data);

  if (node.data && node.data.props && node.data.props.designId === "page") {
    properties = {
      ...properties,
      on: {
        ...properties.on,
        dragover(e) {
          e.preventDefault();
        },
        dragenter(e) {
          e.target.classList.add("dragenter");
        },
        dragleave(e) {
          e.target.classList.remove("dragenter");
        },
        drop: self.dropHandler,
      },
    };
  } else {
    if (node.data && node.data.attrs && node.data.attrs.droppable) {
      properties = {
        ...properties,
        on: {
          drop: self.dropHandler,
        },
      };
    }
  }

  // @todo refactor
  if (!properties) properties = {};
  if (!properties.attrs) properties.attrs = {};
  // if (!properties.attrs.id) properties.attrs.id = Math.random();

  self.$set(properties.attrs, "mode", self.osMode);
  self.$set(properties, "key", properties.attrs.id + "-" + self.osMode);

  if (node.data && node.data.props && node.data.props.configurable) {
    if (!properties.on) properties.on = {};
    properties.on["!click"] = (event) =>
      self.clickHandler(event, properties.attrs.id);
  }
  return h(node.tag, properties, children.length > 0 ? children : node.text);
};

export default {
  name: "preview-mode",
  props: ["page"],
  render(h) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    return createComponent(this.page, h, self);
  },
  computed: {
    ...mapState("workspace/session", ["screen"]),
    osMode() {
      return this.screen.os === "ANDROID" ? "md" : "ios";
    },
  },
  methods: {
    dropHandler(e) {
      e.preventDefault();
      e.stopPropagation();
      e.target.classList.remove("dragenter");
      if (e.dataTransfer.getData("newItem")) {
        if (e.dataTransfer.getData("plugin")) {
          const plugin = JSON.parse(e.dataTransfer.getData("item"));
          this.addPlugin(plugin);
        } else {
          const VNode = JSON.parse(e.dataTransfer.getData("item"));
          const index = e.target.id.includes("header") ? 0 : 1;
          this.registerDesignComponent({
            targetId: this.page.children[index].data.attrs.id,
            VNode,
          });
        }
      }
    },

    clickHandler(e, targetId) {
      // fix for popup-elements' click problem.
      if (document.getElementsByTagName("ion-app")[0].children.length > 2)
        return;

      // console.log("screen:previewMode::click ", targetId);
      this.$root.$emit("workspace/settings::targetId", targetId);
      /*
      if (node.clickable) {
        e.preventDefault();
        this.selectElementById(node.data.attrs.id);
      } else if (node.name === "content") {
        this.selectElement(null);
      }*/
    },

    dragStartHandler(e, node) {
      if (node.data.attrs.draggable) {
        e.dataTransfer.dropEffect = "copy";
        e.dataTransfer.effectAllowed = "all";
        e.dataTransfer.setData("id", JSON.stringify(e.target.id));
      }
    },

    ...mapActions("workspace/session", ["registerDesignComponent"]),
    ...mapActions("app", ["selectElementById"]),
    ...mapMutations("app", ["selectElement", "addPlugin"]),
  },
};
</script>

<style lang="scss">
ion-header {
  min-height: 5vh;
}
ion-title.ios {
  padding-inline-start: 0 !important;
  padding-inline-end: 0 !important;
  padding: 0 !important;
}
ion-content {
  ion-col {
    border: 1px dashed #aaa;
    min-height: 50px;
  }
}
.dragenter {
  // border: 1px dashed red;
  content: "Move here";
  z-index: 9999;
}
.selectedEl {
  border: 1px solid red;
  box-sizing: border-box;
}
</style>
