<template>
  <div class="tabbed-content-design">
    <div
      :is="designElement"
      v-for="(title, designElement) in $root.settings.designElementsOrder"
      :key="designElement"
      :title="title"
    ></div>
  </div>
</template>

<script>
import components from "./components.vue";
import plugins from "./plugins.vue";
import services from "./services.vue";
export default {
  components: {
    components,
    plugins,
    services,
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../../shared/vars.scss";

.tabbed-content-design {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 100px);
  overflow: hidden;
}
</style>
