export default {
  info: {
    disabled: false,
    title: "Cartoonizer",
    img: require(`../../../assets/img/icons/face_id.svg`),
  },
  structure: {
    key: "Cartoonizer",
  },
};
